.custom-select {
  position: relative;
  min-width: 220px;
  max-width: 100%;
  font-size: 1rem;
  pointer-events: auto;
}

.select-button {
  width: 100%;
  background-color: #fff;
  padding: 0px 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.arrow {
  transition: transform ease-in-out 0.3s;
}

.select-dropdown {
  position: absolute;
  list-style: none;
  width: 100%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  transition: 0.2s ease;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.select-dropdown li {
  position: relative;
  cursor: pointer;
  padding: 8px 10px;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover {
  background-color: #f2f2f2;
}

.custom-select.active .arrow {
  transform: rotate(180deg);
}

.custom-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
