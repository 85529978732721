.modal-coleta-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: none;
}

.modal-coleta {
  min-width: 277px;
  background-color: white;
  border-radius: 18px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-family: Roboto;
  max-width: 320px;
  pointer-events: auto;
  position: absolute;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
}

.modal-coleta ul {
  color: #757575;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-body {
  padding: 8px 16px 16px;
}

.bold {
  font-weight: 700;
  color: #616161;
}

.divider {
  margin: 8px 16px;
  border: 1px solid #f2f5f7;
  width: 80%;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 2px;
  grid-gap: 2px;
  gap: 2px;
  position: relative;
}

.modal-header h3,
.modal-header p {
  padding: 0;
  margin: 0;
}

.modal-header h3 {
  color: #333333;
}

.modal-header p {
  color: #9e9e9e;
}

.modal-header .coleta-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.modal-header .coleta-icon img {
  width: 24px;
}

.modal-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-subtitle {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-marcador-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  pointer-events: all;
  min-width: 200px;
  min-height: 80px;
  -webkit-transform: translate(-35%, -75%);
          transform: translate(-35%, -75%);
}

.coletas-cluster .modal-marcador-wrapper {
  position: unset;
  height: 60px;
}

.modal-marcador {
  min-width: 100px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-family: Roboto;
  pointer-events: all;
  position: absolute;

}

.coletas-cluster .modal-marcador {
  -webkit-transform: none;
          transform: none;
}

.modal-marcador ul {
  color: #757575;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-body {
  padding: 8px 16px 16px;
}

.bold {
  font-weight: 700;
  color: #616161;
}

.divider {
  margin: 8px 16px;
  border: 1px solid #f2f5f7;
  width: 80%;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 2px;
  grid-gap: 2px;
  gap: 2px;
  position: relative;
}

.modal-header h3,
.modal-header p {
  padding: 0;
  margin: 0;
}

.modal-header h3 {
  color: #333333;
}

.modal-header p {
  color: #9e9e9e;
}

.modal-header .marcador-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.modal-header .marcador-icon img {
  width: 36px;
}

.modal-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-subtitle {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.captionFooter {
  padding: 8px;
  max-width: 760px;
  background-color: #fff;
  font-family: Roboto;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.captionFooterTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #414243;
  display: flex;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 8px;
  height: 100%;
  min-width: 250px;
  align-items: center;
  padding: 0;
}

.captionFooter ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.captionFooter ul div {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-bottom: 8px;
}

.captionFooter ul li {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #424242;
}

.greenPin {
  color: #cbd938;
}

.redPin {
  color: #f44336;
}

.grayPin {
  color: #bdbdbd;
}

.button {
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  pointer-events: all;
  position: relative;
}

.title {
  font-family: Roboto;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  text-align: left;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 4px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-top: 0px;
}

.sub-menu label {
  display: flex;
  align-items: center;
  font-family: Roboto;
  color: #212121;
}

.sub-menu label input {
  margin-right: 8px;
}

.custom-select {
  position: relative;
  min-width: 220px;
  max-width: 100%;
  font-size: 1rem;
  pointer-events: auto;
}

.select-button {
  width: 100%;
  background-color: #fff;
  padding: 0px 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.arrow {
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

.select-dropdown {
  position: absolute;
  list-style: none;
  width: 100%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  transition: 0.2s ease;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.select-dropdown li {
  position: relative;
  cursor: pointer;
  padding: 8px 10px;
}

.select-dropdown::-webkit-scrollbar {
  width: 7px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

.select-dropdown li:hover {
  background-color: #f2f2f2;
}

.custom-select.active .arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.custom-select.active .select-dropdown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 16px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

