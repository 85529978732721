.title {
  font-family: Roboto;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  text-align: left;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
}
