.captionFooter {
  padding: 8px;
  max-width: 760px;
  background-color: #fff;
  font-family: Roboto;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.captionFooterTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #414243;
  display: flex;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 8px;
  height: 100%;
  min-width: 250px;
  align-items: center;
  padding: 0;
}

.captionFooter ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.captionFooter ul div {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.captionFooter ul li {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #424242;
}

.greenPin {
  color: #cbd938;
}

.redPin {
  color: #f44336;
}

.grayPin {
  color: #bdbdbd;
}
