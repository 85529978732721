.button {
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  pointer-events: all;
  position: relative;
}
