.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 4px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  margin-top: 0px;
}

.sub-menu label {
  display: flex;
  align-items: center;
  font-family: Roboto;
  color: #212121;
}

.sub-menu label input {
  margin-right: 8px;
}
