.modal-coleta-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: none;
}

.modal-coleta {
  min-width: 277px;
  background-color: white;
  border-radius: 18px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-family: Roboto;
  max-width: 320px;
  pointer-events: auto;
  position: absolute;
  transform: translate(-50%, -70%);
}

.modal-coleta ul {
  color: #757575;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-body {
  padding: 8px 16px 16px;
}

.bold {
  font-weight: 700;
  color: #616161;
}

.divider {
  margin: 8px 16px;
  border: 1px solid #f2f5f7;
  width: 80%;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 2px;
  gap: 2px;
  position: relative;
}

.modal-header h3,
.modal-header p {
  padding: 0;
  margin: 0;
}

.modal-header h3 {
  color: #333333;
}

.modal-header p {
  color: #9e9e9e;
}

.modal-header .coleta-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.modal-header .coleta-icon img {
  width: 24px;
}

.modal-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-subtitle {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
